import React from 'react'
import Avatar from '@mui/material/Avatar'
import Colors from '#/styles/Colors'
import { usePermissions } from '#/store/hooks/permissions'
import DiscardIcon from '#/icons/Discard'
import AnimalIcon from '#/icons/Animal'
import BatchIcon from '#/icons/Batch'

export default function AvatarCell({ discarded, icon, collar }) {
  const {
    permissions: { farm, system },
  } = usePermissions({
    system: ['view_collar_status_report'],
    farm: ['view_collar_status_report'],
  })

  const iconsList = {
    discard: <DiscardIcon sx={{ fontSize: '30px' }} />,
    animal: <AnimalIcon sx={{ fontSize: '30px' }} />,
    batch: <BatchIcon sx={{ fontSize: '28px' }} />,
  }

  let backgroundColor

  if (collar?.code || collar?.status) {
    if (icon === 'batch') {
      backgroundColor = Colors.batch.backgroundColor
    }

    if (icon === 'animal') {
      backgroundColor = Colors.devices.ctech.normal.backgroundColor

      if (farm.viewCollarStatusReport || system.viewCollarStatusReport) {
        if (collar.status === 'revision') {
          backgroundColor = Colors.devices.ctech.revision.backgroundColor
        }
        if (collar.status === 'replace') {
          backgroundColor = Colors.devices.ctech.replace.backgroundColor
        }
      }
    }
  }

  return (
    <Avatar style={!discarded ? { backgroundColor } : null}>
      {iconsList[discarded ? 'discard' : icon]}
    </Avatar>
  )
}
