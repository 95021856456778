import { ThemeProvider } from '@mui/material/styles'
import React, { Suspense, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { Toaster } from 'sonner'
import { isDev } from '#/utils'
import Flex from '#/components/Flex'
import ErrorBoundary from '#/components/ErrorBoundary'
import EnvTag from '#/components/EnvTag'
import Routes from './routes'
import { HelpPageCheck } from './components/HelpPages'
import Consent from '#/components/Consent'
import defaultTheme from '#/styles/Themes'
import { getTheme } from '#/store/ducks/app'
import PageLoader from '#/components/PageLoader'

export default function App() {
  // State to manage the color scheme based on system preference or manual setting
  const [systemColorScheme, setSystemColorScheme] = useState('light')

  // Redux selector to get the current theme mode from the store
  const mode = useSelector(getTheme, isEqual)

  React.useEffect(() => {
    // Check if the theme mode is set to 'auto' to determine based on system preference
    if (mode === 'auto') {
      // Check if there is support for Media Query Level 5 and if the system prefers dark mode
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        // Set the system color scheme to dark if dark mode is preferred
        setSystemColorScheme('dark')
      }
    } else {
      // Set the system color scheme to the manually selected mode
      setSystemColorScheme(mode)
    }
  }, [mode])

  const theme = React.useMemo(
    () => defaultTheme(systemColorScheme),
    [systemColorScheme],
  )

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <PageLoader />
        <Router>
          <Suspense
            fallback={
              <Flex>
                <CircularProgress />
              </Flex>
            }
          >
            <Routes />
          </Suspense>
        </Router>
        {isDev && <EnvTag />}
        <HelpPageCheck />
        <Consent />
        <Toaster richColors position="bottom-center" />
      </ErrorBoundary>
    </ThemeProvider>
  )
}
